export function setObjectInStorage(key, value) {
  const formatValue = JSON.stringify(value)
  localStorage.setItem(key,  formatValue)
}

export function getObjectInStorage(key)  {
  const value = localStorage.getItem(key)
  if (value !== undefined && value !== '' && value !== 'undefined') {
    return JSON.parse(value)
  }
  return {}
}

export function removeItemInStorage(key) {
  localStorage.removeItem(key)
}
