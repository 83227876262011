import axios from 'axios'
import { getObjectInStorage } from "./useStorage"

const getMaintenanceStatus = async (countryCode) => {
  const url = `${process.env.REGISTERS_URL}/verify-maintenance-country/${countryCode}`
  const headers = { 'x-api-key': process.env.REGISTERS_X_API_KEY }

  return await axios.get(url, { headers }).then(response => (
    response.data.maintenance
  ))
}

export const useMaintenance = async () => {
  const bypassMaintenanceMode = window.location.href.includes(process.env.INTERNAL_URL)

  if (bypassMaintenanceMode) return

  const currentLocale = getObjectInStorage('currentLocale')
  const country = currentLocale.region.toLowerCase()

  const isInMaintenanceMode = await getMaintenanceStatus(country)

  if (isInMaintenanceMode) {
    const language = currentLocale.language
    window.location.href = `${process.env.MAINTENANCE_URL}?lang=${language}`
  }
}
